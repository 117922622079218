<template>
   <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('videos.deleted') }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col cols="12" class="px-4">
          <b-overlay :show="showOverlay">
            <MediaGridCMS :media="media" />
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import MediaGridCMS from '@/components/admin/_utils_/MediaGridCMS.vue'
import NavBar from "@/components/common/NavBar.vue"
export default {
  name : 'PendignVideo',
  components: { MediaGridCMS, NavBar },
  data: function() {
    return {
      media: null,
      showOverlay: true
    }
  },
  created : async function () {
    await this.$http.get('/cms/api/media/video/deleted/', { headers: {Authorization: this.getAuthData().accessToken }}).then(r => {
      this.media = r.data.content;
    }).catch(error => { this.handleError(error); })
    this.showOverlay = false;
  }
}
</script>
<style></style>
